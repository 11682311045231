body {
  border: 0;
  margin: 0;
  padding: 0;
}

html, body, #map {
  width: 100vw;
  height: 100vh;
}

.hide {
  display: none;
}

.show {
  display: block;
}

#npubPublicKey, #nsecPrivateKey, #yourUrl {
  word-break: break-all;
}

#relays {
  width: 100%;
}

#badge {
  text-align: center;
  z-index: 1000;
  background-color: red;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  max-width: 50vw;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.header {
  box-sizing: border-box;
  z-index: 10000;
  background-color: #12b591;
  border: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 40px;
  margin: 0;
  padding: 0 4px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.header-content {
  color: #fff;
  text-shadow: 1px 1px 1px #0000004d;
  border: none;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1170px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 40px;
  display: flex;
}

.header-content img {
  vertical-align: middle;
  border: none;
  height: 30px;
  margin-right: 10px;
}

.header-content a {
  color: #fff;
  border: none;
  align-items: center;
  text-decoration: none;
  display: flex;
}

#geochat-notes {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
}
/*# sourceMappingURL=index.523f151f.css.map */
