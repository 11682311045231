body {
  padding: 0;
  margin: 0;
  border: 0;
}
html,
body,
#map {
  height: 100vh;
  width: 100vw;
}
.hide {
  display: none;
}
.show {
  display: block;
}
#npubPublicKey,
#nsecPrivateKey,
#yourUrl {
  word-break: break-all;
}
#relays {
  width: 100%;
}

#badge {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  max-width: 50vw;
  text-align: center;
  z-index: 1000;
  background-color: red;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
.header {
  height: 40px;
  background-color: #12b591;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0 4px;
  border: none;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 10000;
}
.header-content {
  display: flex;
  align-items: center;
  border: none;
  justify-content: space-between;
  width: 100%;
  max-width: 1170px;
  border: none;
  line-height: 40px; /* Vertically center the text */
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  color: white; /* Set text color to white */
  text-shadow: rgba(0, 0, 0, 0.3) 1px 1px 1px; /* Text shadow */
}
.header-content img {
  height: 30px; /* Adjust the image height to fit within the header */
  margin-right: 10px; /* Swapped position, so margin-right is used */
  border: none;
  vertical-align: middle;
}
.header-content a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  border: none;
}
#geochat-notes {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}
